.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 2rem ;
}
.programs-header{
    display: flex;
    gap: 5rem;  /* para separar palabras*/
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}
.program-categories{
    display: flex;
    gap: 1rem;
}
.category{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--gray);
    padding: 2rem;
    border-radius: 5px;
    color: white;
    justify-content: space-between;
    box-shadow: 0px 1px 5px rgba(244, 137, 21, 0.5);
    transition: all 400MS ease;
    
}

.category>:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 5rem ;
    width: 4rem;
    height: 4rem;
    fill: white;
}

.category>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
}

.join-now{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.join-now>img{
    width: 1rem;
}

.category:hover{
    cursor: pointer;
    background: var(--planCard);
    cursor: pointer;
    box-shadow: 5px 5px 30px rgba(244, 137, 21, 1);
    transform: translateY(-3%);
}

@media screen and (max-width: 768px) {
    .Programs{
        margin-top: 2rem;
    }
    .programs-header{
        flex-direction: row;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 15rem;
    }

    .program-categories{
        display: grid;
        grid-column-gap: 30px;
        grid-template-columns: repeat(2, 1fr);
    }
}